// CombinedGallerySlideshow.js
import React, { useState } from 'react';
import './Team.css'; // Make sure the path is correct

// Import images directly
import slide1 from '../assets/img/MicrosoftTeams-image (1).png';
import slide2 from '../assets/img/MicrosoftTeams-image (2).png';
import slide3 from '../assets/img/MicrosoftTeams-image (3).png';
import slide4 from '../assets/img/MicrosoftTeams-image (4).png';
import slide5 from '../assets/img/rafting_24.jpg';
import slide6 from '../assets/img/diwaliParty.jpg';
import slide7 from '../assets/img/match_23.jpg';
import slide8 from '../assets/img/rishikesh_22.jpg';
import { Container } from 'react-bootstrap';

const images = [slide1, slide5, slide3, slide8, slide6, slide2, slide4, slide7];

const Team = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openSlideshow = (image, index) => {
    setSelectedImage(image);
    setCurrentIndex(index);
  };

  const closeSlideshow = () => {
    setSelectedImage(null);
  };

  const nextImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    setSelectedImage(images[(currentIndex + 1) % images.length]);
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    setSelectedImage(images[(currentIndex - 1 + images.length) % images.length]);
  };

  return (
    <section id="gallery-slideshow" className="gallery-slideshow-section">
      <Container>
      <div className="team-mem-header">
          <h2>Team Bonding in Action: Fun, Laughter, and Collaboration!</h2>
          <p>Our team is dedicated to delivering the best service and achieving outstanding results.</p>
        </div>
      <div className="gallery-content">
        <div className="gallery">
          {images.map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`Slide ${index + 1}`}
              onClick={() => openSlideshow(img, index)}
              className="gallery-image"
            />
          ))}
        </div>
        {selectedImage && (
          <div className="slideshow-overlay">
            <div className="slideshow-content">
              <button className="slideshow-close" onClick={closeSlideshow}>
                &times;
              </button>
              <button className="slideshow-prev" onClick={prevImage}>
                &#10094;
              </button>
              <img src={selectedImage} alt="Slideshow" className="slideshow-image" />
              <button className="slideshow-next" onClick={nextImage}>
                &#10095;
              </button>
            </div>
          </div>
        )}
      </div>
      </Container>
    </section>
  );
};

export default Team;
